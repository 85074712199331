import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '@components/common/Layout';
import { Section, Container, ImageCaption } from '@components/global';

const About = () => (
  <StaticQuery
    query={graphql`
      query {
        imageTop: file(
          sourceInstanceName: { eq: "photos" }
          name: { eq: "RBHS_2b" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <Section>
          <Container>
            <Image>
              <Img fluid={data.imageTop.childImageSharp.fluid} />
              <ImageCaption>Ron Brown High School Young Kings meet Morehouse College Student Government Association Delegates</ImageCaption>
            </Image>
            <Grid container justify="center">
              <Grid item xs={10}>
                <h1>About Us</h1>
                <Paragraph>
                  The Foundation to Support the Young Kings, Inc., was established to champion the Ron Brown College Preparatory High School.
                  Foundation Chairman Richard "Dickie" Carter has dedicated himself to the school's success since its inception in 2016.
                </Paragraph>
                <Paragraph>
                  The Ron Brown College Preparatory High School is Washington, D.C.'s first all-male public high school.
                  RBHS represents an unparalleled effort to empower young men of color in the District of Columbia.
                </Paragraph>
                <Paragraph>
                  RBHS recognizes the importance of educating and developing the entire young man.
                  The school offers a rigorous, comprehensive curriculum designed to ensure that every Young King who enters the school can graduate ready for success in college and beyond.
                  Through culturally responsive teaching and social-emotional learning opportunities,
                  RBHS helps students prepare to navigate their post-high school pursuits.
                </Paragraph>
                <div>
                  The school's mission is grounded in three pillars:
                  <ul>
                    <li>
                      Building educated young men of high character
                    </li>
                    <li>
                      Encouraging young men to be life-long learners
                    </li>
                    <li>
                      Supporting young men in service to the community
                    </li>
                  </ul>
                </div>
                <Paragraph>
                  Chairman Carter comments: "I am very impressed with the progress the school has achieved in the areas of retention, attendance, and academic performance.
                  When I observe what is going on at the school, I see the molding of fine young men of color with high standards,
                  respect for their teachers, neighbors, and community, and a realization that these are our future leaders."
                </Paragraph>
                <Paragraph>
                  The Foundation to Support the Young Kings, Inc.,
                  is committed to raising awareness about the Ron Brown College Preparatory High School
                  and building a network of supporters and mentors to advance the school's mission.
                </Paragraph>
              </Grid>
            </Grid>
          </Container>

        </Section>
      </Layout>
    )}
  />
);

const Image = styled.figure`
  margin: 0;
  width: 100%;
  padding-bottom: 50px;
`;

const Paragraph = styled.p`
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 15px;
`;

export default About;